import { Card, TableContainer } from '@mui/material';
import { Table } from 'components/table/Table';
import { CurveOptions } from '../constants/curves';
import { ProductActionMenu } from './ProductActionMenu';
import { useGetProductsQuery } from '../api/products.api';

const columns = [
  { label: 'Division', field: 'division', sort: true },
  { label: 'Cost Code', field: 'cost_code', sort: true },
  { label: 'Description', field: 'description', sort: true },
  { label: 'Trade', field: 'trade_id', sort: true, render: (value) => value.trade.name },
  { label: 'Estimated Budget Percent', field: 'percent', sort: true },
  { label: 'Estimated Labor Percent', field: 'labor_percent', sort: true },
  { label: 'Estimated Material Percent', field: 'material_percent', sort: true },
  { label: 'COGS Labor %', field: 'cogs_labor_percent', sort: true },
  { label: 'COGS Material %', field: 'cogs_material_percent', sort: true },
  { label: 'COGS Per Hour', field: 'cogs_per_hour', sort: true },
  {
    label: 'Labor Curve',
    field: 'labor_curve',
    sort: true,
    render: (value) => CurveOptions?.find((curve) => curve.id === value.labor_curve)?.name ?? 'N/A',
  },
  {
    label: 'Material Curve',
    field: 'material_curve',
    sort: true,
    render: (value) =>
      CurveOptions?.find((curve) => curve.id === value.material_curve)?.name ?? 'N/A',
  },
  {
    label: '',
    field: 'actions',
    cellProps: { align: 'right' },
    render: (product) => <ProductActionMenu product={product} />,
  },
];

export const ProductTable = () => {
  const { data: products = [], isLoading, isFetching } = useGetProductsQuery();
  const TableProps: any = { defaultOrder: 'asc' };

  return (
    <TableContainer component={Card}>
      <Table
        columns={columns}
        {...TableProps}
        isFetching={isFetching}
        isLoading={isLoading}
        rows={products}
      />
    </TableContainer>
  );
};
